<template>
  <div class="news-list-container">
    <el-row class="header" type="flex">
      <el-col :span="18" class="search-option">
        <el-input
          v-model="username"
          class="search-input"
          placeholder="邮箱或手机"
        ></el-input>
        <el-button style="margin: 0 8px;" type="primary" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          type="warning"
          style="margin: 0 8px; float: right;"
          @click="
            postForm = {};
            showCreateDialog = true;
          "
        >
          添加黑名单
        </el-button>
      </el-col>
    </el-row>

    <div class="content">
      <el-table :data="blacklist.items" border stripe>
        <el-table-column
          v-for="col in columns"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
        <el-table-column label="Action" width="300" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row.id)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :current-page="currentPage"
        :page-sizes="[30]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="blacklist.total"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="'增加'"
      :visible.sync="showCreateDialog"
      width="60%"
      @close="showCreateDialog = false"
    >
      <el-col :span="18" class="search-option">
        <el-input
          v-model="username2"
          class="search-input"
          placeholder="邮箱或手机"
        ></el-input>
        <el-button
          style="margin: 0 8px;"
          type="primary"
          @click="handleSearchUser"
        >
          搜索
        </el-button>
      </el-col>
      <el-table :data="userList.items" border stripe>
        <el-table-column
          v-for="col in columns2"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
        <el-table-column label="Action" width="300" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="add(scope.row.id)">
              添加
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/configs/index";

const URL = config.url + "/admin_wallet/api/admin/activity/blacklist";
const USER_URL = config.url + "/admin_wallet/api/admin/getUserList";

export default {
  data() {
    return {
      username: "",
      username2: "",
      categoryType: "0",
      blacklist: {},
      userList: {},
      columns: [
        {
          id: "user_id",
          label: "用户id",
          width: 100,
          align: "center",
        },
        {
          id: "mobile",
          label: "手机",
          width: 180,
        },
        {
          id: "email",
          label: "邮箱",
          width: 180,
        },
        {
          id: "created_at",
          label: "添加时间",
          width: 360,
        },
      ],
      columns2: [
        {
          id: "id",
          label: "用户id",
          width: 100,
          align: "center",
        },
        {
          id: "mobile_prefix",
          label: "区号",
          width: 180,
        },
        {
          id: "mobile",
          label: "手机",
          width: 180,
        },
        {
          id: "email",
          label: "邮箱",
          width: 180,
        },
      ],
      currentPage: 1,
      pageSize: 30,
      postForm: {},
      showCreateDialog: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const params = {};
        params.page = this.currentPage;
        params.page_size = this.pageSize;
        if (this.username) {
          params.username = this.username;
        }
        const { data } = await axios.get(URL, { params });
        if (+data.code === -1) {
          return;
        }
        const blacklist = data.data || [];
        if (blacklist) {
          this.blacklist = {
            items: blacklist.data_list,
            count: blacklist.total_num,
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleSearch() {
      this.fetchData();
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    handleDelete(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delete(id);
      });
    },
    async handleSearchUser() {
      try {
        const params = {};
        params.page = 1;
        params.pageSize = this.pageSize;

        if (this.username2) {
          params.userName = this.username2;
        } else {
          this.$message({
            message: "请输入用户名",
            duration: 3000,
            type: "error",
          });
          return;
        }
        const { data } = await axios.get(USER_URL, { params });
        if (+data.code === -1) {
          return;
        }
        const userList = data.data || [];
        if (userList) {
          this.userList = {
            items: userList.data_list,
            count: userList.total_num,
          };
        }
      } catch (error) {
        console.log(error);
      }
    },
    async add(id) {
      console.log(id);
      try {
        const res = await axios.post(URL, {
          user_id: id,
        });
        if (+res.data.code === 0) {
          this.$message({ message: "操作成功", type: "success" });
          this.showCreateDialog = false;
          this.fetchData();
        } else {
          this.$message({ message: res.data.msg, type: "error" });
        }
      } catch (error) {
        console.log(error);
        this.$message({ message: "操作失败", type: "error" });
      }
    },
    async delete(id) {
      try {
        const res = await axios.delete(`${URL}/${id}`);
        if (res && res.data && +res.data.code === 0) {
          this.$message({ message: "成功", duration: 3000, type: "success" });
          this.fetchData();
        } else {
          this.$message({
            message: res.data.msg,
            duration: 3000,
            type: "fail",
          });
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
        this.$message({ message: "操作失败", duration: 3000, type: "fail" });
      }
    },
    async onSubmit() {
      try {
        const data = this.postForm;
        const res = await axios.post(URL, data);
        if (+res.data.code === 0) {
          this.$message({ message: "操作成功", type: "success" });
          this.showCreateDialog = false;
          this.fetchData();
        } else {
          this.$message({ message: res.data.msg, type: "error" });
        }
      } catch (error) {
        console.log(error);
        this.$message({ message: "操作失败", type: "error" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.news-list-container {
  margin: 0;

  .header {
    margin: 20px;
  }

  .search-input {
    width: 250px;
  }

  .create-option {
    display: flex;
    justify-content: flex-end;
  }

  .content {
    padding: 0 20px 20px;
  }
}
</style>
